module.exports = [{
      plugin: require('/Users/raghuvirk/go/src/github.com/d4ncer/raghuvirkasturi.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-58519126-1"},
    },{
      plugin: require('/Users/raghuvirk/go/src/github.com/d4ncer/raghuvirkasturi.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/raghuvirk/go/src/github.com/d4ncer/raghuvirkasturi.com/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/raghuvirk/go/src/github.com/d4ncer/raghuvirkasturi.com/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"svv4czx"}},
    },{
      plugin: require('/Users/raghuvirk/go/src/github.com/d4ncer/raghuvirkasturi.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
